export enum ChainNum {
    ETHEREUM = 1,
    POLYGON = 137,
    MUMBAI = 80001,
}

export type IChainName = {
    [key in ChainNum]: string;
};

export type ChainId = keyof IChainName;

export const chainName: IChainName = {
    [ChainNum.ETHEREUM]: "ethereum",
    [ChainNum.POLYGON]: "matic",
    [ChainNum.MUMBAI]: "mumbai",
}

export type LinkType = 'contractAddress' | 'network' | 'opensea';

export type ContractAddressFn = (address: string) => string;;
export type OpenseaFn = (address: string, tokenId: string) => string; 

export const chainLinkUrl = (chainId: ChainId, type: LinkType): string | ContractAddressFn | OpenseaFn => {
    switch (type) {
        case 'network':
            return chainId === ChainNum.MUMBAI ? 'https://mumbai.polygonscan.com/' : 'https://polygon.technology/';
        case 'contractAddress':
            return (address: string) => `https://${chainId === ChainNum.MUMBAI ? 'mumbai.' : ''}polygonscan.com/address/${address}`;
        case 'opensea':
            return (address: string, tokenId: string) => `https://${chainId === ChainNum.MUMBAI ? 'testnets.' : ''}opensea.io/assets/${chainName[chainId]}/${address}/${tokenId}`
        default: 
            return ""
    }
}

export const linkAccordingToChainAndType = (chainId: ChainId) => (type: LinkType) => chainLinkUrl(chainId, type)