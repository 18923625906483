import { ethers } from "ethers";

// const POLYGON_CHAIN_ID = "0x13881";
const POLYGON_CHAIN_ID = "0x89";

export const isWeb3AndMetamaskInstalled = () => {
  const { ethereum } = window as any;
  return ethereum && ethereum.isMetaMask;
};

export const connectWallet = async () => {
  const { ethereum } = window as any;
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  const account = accounts[0];  
  return account;
};

export const addPolygonNetwork = async () => {
  const { ethereum } = window as any;
  await ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: POLYGON_CHAIN_ID,
        chainName: "Polygon",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        // rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
        // rpcUrls: ["https://rpc-mainnet.matic.network"],
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://polygonscan.com/"],
        // blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      },
    ],
  });
};

export const switchToPolygonNetwork = async () => {
  const { ethereum } = window as any;
  let retry = true;
  while (retry) {
    retry = false;
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: POLYGON_CHAIN_ID }],
      });
      return true;
    } catch (error: any) {
      if (error.code === 4902 || error.code === -32603) {
        try {
          await addPolygonNetwork();
          retry = true;
        } catch (addError) {
          throw addError;
        }
      }
    }
  }
  return false;
};

export const ensurePolygonNetwork = async () => {
  const { ethereum } = window as any;
  const chainId = await ethereum.request({ method: "eth_chainId" });
  if (chainId !== POLYGON_CHAIN_ID) {
    return switchToPolygonNetwork();
  }
  return true;
};

export const validateChainId = async () => {
  const { ethereum } = window as any;
  const chainId = await ethereum.request({ method: "eth_chainId" });
  return chainId === POLYGON_CHAIN_ID;
};

export const setupEventHandlersWeb3 = () => {
  const { ethereum } = window as any;
  ethereum.on("chainChanged", () => {
    window.location.reload();
  });
  ethereum.on("accountsChanged", () => {
    window.location.reload();
  });
};

export const createNFTContract = async (
  abi: any,
  bytecode: string,
  ...arg: any[]
) => {
  const { ethereum } = window as any;
  const provider = new ethers.providers.Web3Provider(ethereum);

  const signer = provider.getSigner();
  const factory = new ethers.ContractFactory(abi, bytecode, signer);
  const contract = await factory.deploy(...arg);
  return contract;
};

export const getTransactionByHash = async (txHash: string) => {
  const { ethereum } = window as any;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const tx = await provider.getTransaction(txHash);
  return tx;
};

export const getTransactionReceipt = async (txHash: string) => {
  const { ethereum } = window as any;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const receipt = await provider.getTransactionReceipt(txHash);
  return receipt;
};

export const getNFTContract = async (abi: any, address: string) => {
  const { ethereum } = window as any;
  const provider = new ethers.providers.Web3Provider(ethereum);
  const contract = new ethers.Contract(address, abi, provider);
  return contract;
};

export const continueInMetamask = () => {
  const currentUrl = window.location.href;
  const urlWithoutHttps = currentUrl.replace("https://", "");
  const urlWithoutHttp = urlWithoutHttps.replace("http://", "");
  const deeplink = "https://metamask.app.link/dapp/" + urlWithoutHttp;
  window.open(deeplink);
};
