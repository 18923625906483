import { EmptyBox } from "../../component/empty_box";
import { OuterContainer } from "../../component/outer_container";

export const SharedPage = () => {
  return (
    <OuterContainer>
      <div className="h-[64px] w-full">
        <EmptyBox text="Header" />
      </div>
      <div className="h-full w-full">
        <EmptyBox text="Body" />
      </div>
    </OuterContainer>
  );
};
