import axios from "axios";
import { BASE_API_URL } from "./api_const";

const instance = axios.create({
  baseURL: BASE_API_URL,
});

instance.defaults.timeout = 4000;

export enum ClaimCodeStatus {
  Deployed = "deployed",
  Claimed = "claimed"
}

export type GetInfoByClaimCodeResponse = {
    id: string;
    code: string;
    chain_id: number;
    contract_address: string;
    token_id: string;
    claim_wallet_address: string;
    status: ClaimCodeStatus;
    expires_at: string;
    image_url: string;
    sender_link: string;
    sender_name: string;
    sender_logo_file_id: string;
    card_content: '';
    share_content: '';
}

export const getInfoByClaimCode = async (code: string) => {
  try {
    const {data} = await instance.get<GetInfoByClaimCodeResponse>(BASE_API_URL + "/claim-records/by-code", {
      params: {
        code
      }
    });
    return data;
  } catch(e) {
    console.log('getInfoByClaimCode error: ', e);
    if (axios.isAxiosError(e)) {
      throw e.message
    } else {
      throw Error('Cannot get the greeting card');
    }
  }
};


export const claimAndMint = async (id: string, walletAddress: string) => {
  try {
    const {data} = await instance.post(BASE_API_URL + `/claim-records/${id}/claim`, {
      target_wallet_address: walletAddress
    });
    return data;
  } catch(e) {
    console.log('claimAndMint error: ', e);
    if (axios.isAxiosError(e)) {
      throw e.message
    } else {
      throw Error('Cannot mint this nft right now');
    }
  }
}