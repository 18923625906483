import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatTime = (rawTime: string) => dayjs.utc(rawTime).format('YYYY-MM-DD HH:mm:ss');

export const getTimeStamp = (timeStr: string) => new Date(timeStr).getTime();

export const getNowTimeStamp = () => new Date().getTime();
