
import { Modal } from "antd-mobile";
import { Button } from "antd";
import polygon from "../asset/icon/polygon.svg";
import metamask from "../asset/icon/metamask.svg";
import {
  createNFTContract,
} from "../api/web3";
import { NFTv1 } from "../const/nftv1";
import { NavigateFunction } from "react-router-dom";
import { checkWallet, checkWalletInstalled } from "../utils/wallet";

export const ShowMintModal = (
  nftName: string,
  nftBaseUri: string,
  wid: string,
  navigate: NavigateFunction
) => {
  const handle = Modal.show({
    content: (
      <div className="flex flex-col items-center gap-4 max-w-[400px]">
        <div className="flex flex-row gap-4">
          <img src={metamask} alt="metamask" />
          <img src={polygon} alt="polygon" />
        </div>
        <div className="text-[14px] text-[#333333] text-center">
          You will be redirected to Metamask to mint this NFT using the Polygon
          network
        </div>
        <div className="flex flex-row gap-4">
          <Button
            type="default"
            shape="round"
            size="large"
            onClick={() => {
              handle.close();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={async() => {
              try {
                await checkWalletInstalled()
                await checkWallet();
                // Mint NFT
                const contract = await createNFTContract(
                  NFTv1.abi,
                  NFTv1.bytecode,
                  nftName,
                  nftBaseUri
                );

                handle.close();

                navigate(
                  "/nft?contract_address=" +
                    contract.address +
                    "&name=" +
                    nftName +
                    "&transaction_hash=" +
                    contract.deployTransaction.hash +
                    "&wid=" +
                    wid
                );
              } catch(e) {
                console.log('error', e);
              }
            }}
          >
            Authenticate
          </Button>
        </div>
      </div>
    ),
    actions: [],
  });
};
