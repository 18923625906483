import { FC } from "react";
import { Tooltip } from "antd";
import { GetInfoByClaimCodeResponse } from "../../api/greeting_card";
import Polygon from "../../asset/icon/polygon.svg";
import RightArrow from "../../asset/icon/rightArrow.svg";
import { ChainNum, ContractAddressFn, linkAccordingToChainAndType } from "../../utils/chain";


export const GreetingCardInfo: FC<{info: GetInfoByClaimCodeResponse}> = ({
    info
  }) => {
    const contractLink = (linkAccordingToChainAndType(info.chain_id)('contractAddress') as ContractAddressFn)(info.contract_address);
    const networkLink = linkAccordingToChainAndType(info.chain_id)('network');

    return <div className="w-full">
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Blockchain Network</div>
      <div className="font-normal text-[14px]"><a href={networkLink as string} className="flex gap-[4px] text-[#333]"><img src={Polygon} alt="polygon" width={16} height={14}/>{info.chain_id === ChainNum.MUMBAI ? 'MUMBAI' : 'POLYGON'}<img src={RightArrow} alt="right arrow" /></a></div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Contract Address</div>
      <div className="font-normal text-[14px]"><a href={contractLink} className="text-[#7750F8] flex gap-[4px]"><span className="text-ellipsis overflow-hidden block whitespace-nowrap max-w-[120px] md:max-w-[240px]"><Tooltip placement="top" title={info.contract_address}>{info.contract_address}</Tooltip></span> <img src={RightArrow} alt="right arrow" /></a></div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Token Standard</div>
      <div className="font-normal text-[14px]">ERC-721</div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Token ID</div>
      <div className="font-normal text-[14px]"><span className="text-ellipsis overflow-hidden block whitespace-nowrap max-w-[120px] md:max-w-[200px]">{info.token_id}</span></div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Claim Code</div>
      <div className="font-normal text-[14px]"><span className="text-ellipsis overflow-hidden block whitespace-nowrap max-w-[120px] md:max-w-[200px]"><Tooltip placement="top" title={info.code}>{info.code}</Tooltip></span></div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Gas Fee</div>
      <div className="font-normal text-[14px]">FREE</div>
    </div>
    <div className="flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] border-bottom">
      <div className="font-normal text-[14px]">Sender</div>
      <div className="font-normal text-[14px]">{
        info.sender_link ? <a href={info.sender_link} className="text-[#7750F8] flex gap-[4px]"><span className="text-ellipsis overflow-hidden block whitespace-nowrap max-w-[120px] md:max-w-[200px]">{info.sender_name}</span> <img src={RightArrow} alt="right arrow" /></a> : <span className="text-ellipsis overflow-hidden block whitespace-nowrap max-w-[200px]">{info.sender_name}</span>
      }</div>
    </div>
  </div>
  }