export const NFT_IMAGE_BASEURI = "https://storage.googleapis.com/100nft_asset/";
export const getImageUrlByWorkflowId = (wid: string) => {
  return `${NFT_IMAGE_BASEURI}${wid}.jpg`;
};

export const POLYSCAN_BASEURI = "https://mumbai.polygonscan.com/";
export const getPolygonScanUrlByTxHash = (txHash: string) => {
  return `${POLYSCAN_BASEURI}tx/${txHash}`;
};

export const OPENSEA_BASEURI = "https://opensea.io/";
export const getOpenSeaUrlByContractAddress = (contractAddress: string) => {
  return `${OPENSEA_BASEURI}assets/matic/${contractAddress}/0`;
};
