import { SingleRadioBtnProps } from "../component/radio_btn_group/radio_btn_group";

export const STYLE_CUSTOM_HINT: SingleRadioBtnProps[] = [
  {
    title: "Artistic",
    data: {
      style: "artistic",
      text: ", trending on artstation, incredible vibrant colors, dynamic epic composition, foamy stylized water, ray tracing, traditional art by studio ghibli",
      index: 0,
    },
  },
  {
    title: "Portrait",
    data: {
      style: "portrait",
      text: ", concept art by Doug Chiang cinematic, realistic painting, high definition, concept art, portait image, path tracing, serene landscape, high quality, highly detailed, 8K, soft colors, warm colors, turbulent sea, high coherence, anatomically correct, hyperrealistic, concept art, defined face, five fingers, symmetrical",
      index: 1,
    },
  },
  {
    title: "None",
    data: {
      style: "none",
      text: "",
      index: 2,
    },
  },
];
