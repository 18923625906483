import { Button, Tooltip, Radio } from "antd";
import "./override.css";

export interface SingleRadioBtnProps {
  title: string;
  data: any;
}

export interface RadioBtnGroupProps {
  list: SingleRadioBtnProps[];
  currentData?: any;
  onChange?: (data: any) => void;
}

export const RadioBtnGroup = (props: RadioBtnGroupProps) => {
  const currentData = props.currentData || "";
  return (
    <div className="h-full w-full text-left pb-5 roundBtn flex flex-row gap-3">
      {props.list.map((item, index) => {
        return (
          <Button
            key={index}
            type={currentData === item?.data ? "primary" : "default"}
            shape="round"
            size="middle"
            onClick={() => {
              props.onChange && props.onChange(item.data);
            }}
          >
            {item.title}
          </Button>
        );
      })}
    </div>
  );
};
