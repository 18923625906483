import { EmptyBox } from "../../component/empty_box";
import { OuterContainer } from "../../component/outer_container";
import logo from "../../asset/icon/logo_h.svg";
import share from "../../asset/icon/share.svg";
import { Button, Tooltip, Radio } from "antd";
import "./override.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ImgSelect } from "../../component/img_select/img_select";
import { IMG_2D, IMG_3D, IMG_Pixel } from "../../const/img_select_consts";
import { useEffect, useState } from "react";
import { RadioBtnGroup } from "../../component/radio_btn_group/radio_btn_group";
import { STYLE_SELECT_RADIO_BTN_GROUP } from "../../const/style_select_radio_btn_const";
import {
  createImg2ImgWorkflow,
  createTxt2ImgWorkflow,
} from "../../api/createWorkflow";
import { Toast } from "antd-mobile";
import { getIndexFromQR } from "./select_url_redirect_utils";
import { Input } from "antd";
import { Slider } from "antd-mobile";
import { STYLE_CUSTOM_HINT } from "../../const/custom_style_hint";
const { TextArea } = Input;

const getImgDataByStyle = (style: string) => {
  switch (style) {
    case "3d":
      return IMG_3D;
    case "2d":
      return IMG_2D;
    case "pixel":
      return IMG_Pixel;
    default:
      return IMG_3D;
  }
};

export const AvatarSelectPage = () => {
  const navigate = useNavigate();
  const [currentSelectedImg, setCurrentSelectedImg] = useState(0);
  const [currentSelectedStyle, setCurrentSelectedStyle] = useState(
    STYLE_SELECT_RADIO_BTN_GROUP[0].data
  );
  const [currentSelectedSubStyle, setCurrentSelectedSubStyle] = useState(
    STYLE_CUSTOM_HINT[0].data
  );

  const [prompt, setPrompt] = useState("");
  const [cfg, setCfg] = useState(7);

  const [searchParams, setSearchParams] = useSearchParams();
  const qr = searchParams.get("qr");

  useEffect(() => {
    if (!qr) return;

    // Get the qr to index map
    const index = getIndexFromQR(qr);
    if (index === undefined) return;

    // Set the current selected img
    setCurrentSelectedImg(index);
  }, [qr]);

  return (
    <OuterContainer>
      <div className="h-[64px] w-full">
        <div className="flex flex-row h-full w-full">
          <div className="h-full w-full flex flex-col items-start">
            <Link to="/">
              <img src={logo} />
            </Link>
            <div className="text-[14px]">Create your free-to-own AI art</div>
          </div>
          <div className="h-full flex-none flex flex-col items-center justify-center circleBtn">
            {/* <Button
              shape="circle"
              color="primary"
              icon={
                <div className="flex flex-col items-center justify-center">
                  <img src={share} />
                  <div className="text-[12px]">Share</div>
                </div>
              }
              size="large"
            /> */}
          </div>
        </div>
      </div>
      <div className="h-full w-full flex flex-col pt-[10px]">
        <div className="w-full flex flex-col flex-none">
          <div className="h-full w-full text-left">
            <div className="text-[18px] font-bold pb-5">Select a style</div>
          </div>
          <RadioBtnGroup
            list={STYLE_SELECT_RADIO_BTN_GROUP}
            currentData={currentSelectedStyle}
            onChange={(data) => {
              setCurrentSelectedStyle(data);
            }}
          />
        </div>
        <div className="h-full w-full text-left pb-[100px]">
          {/* Img2Img */}
          {currentSelectedStyle !== "custom" && (
            <ImgSelect
              data={getImgDataByStyle(currentSelectedStyle)}
              selected={currentSelectedImg}
              onChange={(index) => {
                setCurrentSelectedImg(index);
              }}
            />
          )}

          {/* Custom */}
          {currentSelectedStyle === "custom" && (
            <div className="w-full flex flex-col flex-none">
              <div className="text-[16px] font-bold ">Prompt Words</div>
              <div className="text-[12px] pb-5">
                Describe the image you want to generate
              </div>
              <TextArea
                style={{ borderRadius: "10px" }}
                placeholder="Descriptions"
                value={prompt}
                rows={6}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
              />
              <div className="text-[16px] font-bold mt-5">Substyle</div>
              <div className="text-[12px] pb-5">
                Choose an art style for your NFT
              </div>
              <RadioBtnGroup
                list={STYLE_CUSTOM_HINT}
                currentData={currentSelectedSubStyle}
                onChange={(data) => {
                  console.log(data);
                  setCurrentSelectedSubStyle(data);
                }}
              />
              <div className="text-[16px] font-bold pt-5">Cfg Scale</div>
              <div className="text-[12px] pb-5">
                How much the image looks like the prompt
              </div>
              <div className="w-full flex flex-row ">
                <Slider
                  className="grow"
                  max={12}
                  min={1}
                  step={0.1}
                  value={cfg}
                  onChange={(v) => {
                    setCfg(v as number);
                  }}
                />
                <div className="text-[12px] mr-5">{cfg}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-8 flex flex-col max-w-2xl w-full px-[60px] roundBtn">
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            // TODO: move this logic out to thunks
            if (currentSelectedStyle === "custom") {
              // Validate prompt
              if (prompt.length === 0) {
                Toast.show({ content: "Please enter a prompt" });
                return;
              }
              // Get the final prompt from the prompt and substyle
              const finalPrompt = `${prompt} ${currentSelectedSubStyle.text}`;
              createTxt2ImgWorkflow(finalPrompt, cfg)
                .then((res) => {
                  // Check if the response is valid
                  if (res?.data?.err === "" && res?.data?.wid) {
                    // Navigate to the next page
                    navigate(
                      `/generate?wid=${
                        res.data.wid
                      }&prompt=${encodeURIComponent(
                        prompt
                      )}&cfg=${encodeURIComponent(
                        cfg
                      )}&txt2img=1&substyle=${encodeURIComponent(
                        currentSelectedSubStyle.index
                      )}`
                    );
                  } else {
                    Toast.show({
                      content:
                        "Failed to create workflow. Err:" + res?.data?.err,
                    });
                  }
                })
                .catch((err) => {
                  console.log("There is an error:", err);
                  Toast.show({
                    content: err.message,
                  });
                });
              return;
            }

            // Not custom, fall back to the default img2img workflow
            // Get the selected image
            const selectedImg =
              getImgDataByStyle(currentSelectedStyle)[currentSelectedImg];
            if (selectedImg) {
              createImg2ImgWorkflow(currentSelectedStyle, selectedImg.index)
                .then((res) => {
                  // Check if the response is valid
                  if (res?.data?.err === "" && res?.data?.wid) {
                    // Navigate to the next page
                    navigate(
                      `/generate?wid=${res.data.wid}&display_style=${selectedImg.display_style}&animal=${selectedImg.name}&c_selected=${selectedImg.index}&c_style=${currentSelectedStyle}`
                    );
                  } else {
                    Toast.show({
                      content:
                        "Failed to create workflow. Err:" + res?.data?.err,
                    });
                  }
                })
                .catch((err) => {
                  console.log("There is an error:", err);
                  Toast.show({
                    content: err.message,
                  });
                });
            }
          }}
        >
          Generate AI art
        </Button>
      </div>
    </OuterContainer>
  );
};
