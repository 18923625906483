import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import {Modal} from "antd";
import { Input } from "antd";
import { Button } from "antd";
import polygon from "../../asset/icon/polygon.svg";
import metamask from "../../asset/icon/metamask.svg";
import celebrate from "../../asset/icon/celebrate.svg";
import close from "../../asset/icon/close.svg";
import { checkWallet, checkWalletInstalled } from "../../utils/wallet";
import "./override.css";

const { TextArea } = Input;

export const HostedMintModalWithAddress: FC<{
  text: ReactNode,
  successText: string,
  visible: boolean,
  setVisible: Dispatch<SetStateAction<boolean>>,
  step: 1 | 2 | 3,
  setStep: Dispatch<SetStateAction<1 | 2 | 3>>,
  id: string;
  mintFunction: (id: string, walletAddress: string, cb: () => void) => void;
}> = ({text, successText, visible, setVisible, step, setStep, id, mintFunction }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    setStep(1);
    setWalletAddress('');
    setIsConfirming(false);
  }, [setStep, visible])

  return <Modal
    open={visible}
    footer={null}
    closable={false}
    width={358}
  >
    <div className="modalContent relative flex flex-col items-center gap-4 max-w-[358px]">
        <button className="absolute right-[-12px] top-[-12px] w-[48px] h-[48px] rounded-full border-solid border-[#E7E8E8] border-2 flex items-center justify-center" onClick={() => setVisible(false)}><img src={close} alt="close"/></button>

        {
          step !== 3 && <>
            <div className="mb-[10px] mt-[14px]">
              <img src={polygon} alt="polygon" />
            </div>
            <div className="text-left">
              {text}
            </div>
            <div className="text-[#333333] self-start font-semibold">Wallet Address</div>

            {
              step === 1 && <div className="flex flex-col gap-[10px] w-full">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => setStep(2)}
                >
                  Enter Address
                </Button>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={async () => {
                    try {
                      await checkWalletInstalled()
                      await checkWallet().then((wallet) => {
                        setStep(2);
                        setWalletAddress(wallet);
                      })
                    } catch(e) {
                      console.log('error', e);
                    }
                  }}
                >
                  <img src={metamask} alt="metamask" className="w-[20px] h-[20px]"/><span>Sync from MetaMask</span>
                </Button>
              </div>
            }
            
            {
              step === 2 && <>
                <TextArea
                  style={{ borderRadius: "10px" }}
                  placeholder="wallet address..."
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  disabled={isConfirming}
                />
                <div className="flex flex-row gap-4 w-full">
                  <Button
                    type="default"
                    shape="round"
                    size="large"
                    onClick={() => setStep(1)}
                    style={{width: '50%'}}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    style={{width: '50%'}}
                    disabled={!walletAddress}
                    loading={isConfirming}
                    onClick={() => {
                      if (isConfirming) return;
                      setIsConfirming(true);
                      mintFunction(id, walletAddress, () => {setIsConfirming(false)})
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            }
          </>

        }
        
        {/* step 3 */}
        {
          step === 3 && <>
            <div>
              <img src={celebrate} alt="congratulations" className="w-[36px] h-[36px]"/>
            </div>
            <div className="text-left">
              {successText}
            </div>
            <Button
              type="primary"
              shape="round"
              size="large"
              className="w-full"
              onClick={() => setVisible(false)}
            >
              That's Cool!
            </Button>
          </>
        }
      </div>
  </Modal>;
};
