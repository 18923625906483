import { Image, Space } from "antd-mobile";
import { useState } from "react";
import s1 from "../../asset/sample/s1.png";
import { SingleImageSelect } from "./single_image_select";

export interface SingleImageData {
  path: string;
  name: string;
  style: string;
  display_style: string;
  index: number;
}

interface ImgSelectProps {
  data: SingleImageData[];
  selected?: number;

  onChange?: (index: number) => void;
}

export const ImgSelect = (props: ImgSelectProps) => {
  const selected = props.selected || 0;
  return (
    <Space wrap justify="center">
      {props.data.map((item, i) => {
        return (
          <SingleImageSelect
            data={item}
            isSelected={i === selected}
            key={item.index}
            onClick={() => {
              props.onChange && props.onChange(i);
            }}
          />
        );
      })}
    </Space>
  );
};
