import { Image, Space, Tag } from "antd-mobile";
import { SingleImageData } from "./img_select";
import { getImgUrl } from "../../const/img_select_consts";

interface SingleImageSelectProps {
  data: SingleImageData;
  isSelected: boolean;
  onClick: () => void;
}
export const SingleImageSelect = (props: SingleImageSelectProps) => {
  const url = getImgUrl(props.data.path);
  return (
    <div className="relative">
      {props.isSelected ? (
        <Image
          src={url}
          width={171}
          height={171}
          fit="cover"
          style={{
            borderRadius: "10px",
            border: "3px solid  #7750f8",
          }}
          onClick={props.onClick || (() => {})}
        />
      ) : (
        <Image
          src={url}
          width={171}
          height={171}
          fit="cover"
          style={{
            borderRadius: "10px",
          }}
          onClick={props.onClick || (() => {})}
        />
      )}

      {props.isSelected && (
        <div className="absolute bottom-[5px] left-[10px]">
          <Tag color="#108ee9" style={{ "--border-radius": "6px" }}>
            {props.data.name}
          </Tag>
        </div>
      )}
    </div>
  );
};
