import { EmptyBox } from "../../component/empty_box";
import { OuterContainer } from "../../component/outer_container";
import logo from "../../asset/icon/logo_h.svg";
import share from "../../asset/icon/share.svg";
import { Button, Tooltip, Radio } from "antd";
import { Image, Space, Toast } from "antd-mobile";
import "./override.css";
import check from "../../asset/icon/check.svg";
import s1 from "../../asset/sample/s1.png";
import {
  Link,
  NavigateFunction,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { ShowShareModal } from "../../component/share_modal";
import { useEffect, useState } from "react";
import { checkWorkflow } from "../../api/checkWorkflow";
import { WF_ERROR, WF_RUNNING, WF_SUCCEED } from "../../const/WFConsts";
import { ImgWaiting, ImgWaitingStatus } from "../../component/img_waiting";
import {
  createImg2ImgWorkflow,
  createTxt2ImgWorkflow,
} from "../../api/createWorkflow";
import { ShowMintModal } from "../../component/mint_modal";
import { getPolygonNFTBaseURI } from "./nft_baseuri_utils";
import { STYLE_CUSTOM_HINT } from "../../const/custom_style_hint";

const errorAndRedirect = (err: string, navigate: NavigateFunction) => {
  Toast.show({
    content: err,
  });
  setTimeout(() => {
    navigate("/");
  }, 5000);
};

enum WorkflowStatus {
  None,
  Running,
  Success,
  Failed,
  NotFound,
}

export const AvatarGeneratePage = () => {
  const navigate = useNavigate();
  const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.None);
  const [generatedImgUrl, setGeneratedImgUrl] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const wid = searchParams.get("wid");
  const display_style = searchParams.get("display_style");
  const animal = searchParams.get("animal");
  const c_selected = searchParams.get("c_selected");
  const c_style = searchParams.get("c_style");
  const txt2img = searchParams.get("txt2img");
  const cfg = searchParams.get("cfg");
  const prompt = searchParams.get("prompt");
  const substyle_index = searchParams.get("substyle");

  const checkCurrentWorkflow = async () => {
    try {
      const result = await checkWorkflow(wid as string);
      if (result.status === 200) {
        // Check the status
        if (result.data.status === WF_RUNNING) {
          setWorkflowStatus(WorkflowStatus.Running);
          return WorkflowStatus.Running;
        } else if (result.data.status === WF_SUCCEED) {
          setWorkflowStatus(WorkflowStatus.Success);
          setGeneratedImgUrl(result.data.url);
          return WorkflowStatus.Success;
        } else if (result.data.status === WF_ERROR) {
          setWorkflowStatus(WorkflowStatus.Failed);
          errorAndRedirect(result.data.error, navigate);
          return WorkflowStatus.Failed;
        } else {
          setWorkflowStatus(WorkflowStatus.Failed);
          errorAndRedirect("Unknown error. Err:" + result.data.err, navigate);
          return WorkflowStatus.Failed;
        }
      } else if (result.status === 404) {
        setWorkflowStatus(WorkflowStatus.NotFound);
        errorAndRedirect("Workflow not found", navigate);
        return WorkflowStatus.NotFound;
      } else {
        setWorkflowStatus(WorkflowStatus.Failed);
        errorAndRedirect("Workflow failed", navigate);
        return WorkflowStatus.Failed;
      }
    } catch (err) {
      setWorkflowStatus(WorkflowStatus.Failed);
      errorAndRedirect("Workflow failed", navigate);
      return WorkflowStatus.Failed;
    }
  };

  useEffect(() => {
    // Check if we have any valid wid
    if (!wid) {
      errorAndRedirect("No workflow id", navigate);
    }

    (async () => {
      // Check the status
      const status = await checkCurrentWorkflow();
      if (status === WorkflowStatus.Running) {
        console.log("Running the timer");
        const interval = setInterval(() => {
          (async () => {
            console.log("Checking the status");
            const result = await checkCurrentWorkflow();
            if (result !== WorkflowStatus.Running) {
              console.log("Clear the timer");
              clearInterval(interval);
              return;
            }
          })();
        }, 3000);
      }
    })();
  }, [wid]);

  if (!wid) {
    return <div></div>;
  }

  return (
    <OuterContainer>
      <div className="h-[64px] w-full">
        <div className="flex flex-row h-full w-full">
          <div className="h-full w-full flex flex-col items-start">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="h-full flex-none flex flex-col items-center justify-center">
            <Button
              shape="circle"
              color="primary"
              icon={<CloseOutlined />}
              size="large"
              onClick={() => {
                navigate("/select");
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-full w-full flex flex-col pt-[10px]">
        <div className="w-full flex flex-col flex-none">
          <div className="h-full w-full text-left">
            {workflowStatus === WorkflowStatus.Running && (
              <div className="text-[14px] pb-5">Generating your AI art...</div>
            )}
            {(workflowStatus === WorkflowStatus.NotFound ||
              workflowStatus === WorkflowStatus.Failed ||
              workflowStatus === WorkflowStatus.None) && (
              <div className="text-[14px] pb-5 text-rose-500">
                Error: Failed to generate your AI art
              </div>
            )}
            {workflowStatus === WorkflowStatus.Success && (
              <div className="text-[14px] pb-5">
                <img src={check} className="inline-block" />
                Process completed, here’s your own AI art:
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full text-left flex flex-col items-center">
          {workflowStatus === WorkflowStatus.Running && (
            <ImgWaiting status={ImgWaitingStatus.Running} />
          )}
          {(workflowStatus === WorkflowStatus.NotFound ||
            workflowStatus === WorkflowStatus.Failed ||
            workflowStatus === WorkflowStatus.None) && (
            <ImgWaiting status={ImgWaitingStatus.Failed} />
          )}
          {workflowStatus === WorkflowStatus.Success && (
            <Image
              src={generatedImgUrl}
              width={"100%"}
              fit="cover"
              style={{
                borderRadius: "10px",
                maxWidth: "50vh",
              }}
            />
          )}
        </div>
        <div className="h-full w-full text-left flex flex-col items-left pt-3">
          <div className="pt-2 text-[18px] font-bold">
            {!txt2img && (animal || "Animal Name")}
            {txt2img && (prompt || "Prompt")}
          </div>
          <div className="text-[16px] text-[#999FA5]">
            {!txt2img && (display_style || "Style Name")}
            {txt2img && "Custom"}
          </div>
        </div>
      </div>
      <div className="fixed bottom-8 flex flex-col-reverse max-w-2xl w-full px-[60px] roundBtn gap-4">
        {workflowStatus === WorkflowStatus.Running && (
          <Button
            type="primary"
            shape="round"
            size="large"
            icon={<SyncOutlined spin />}
            disabled={workflowStatus === WorkflowStatus.Running}
          >
            Generating your NFT...
          </Button>
        )}

        {workflowStatus === WorkflowStatus.Success && (
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              if (txt2img === "1") {
                if (
                  !prompt ||
                  prompt === "" ||
                  !cfg ||
                  cfg === "" ||
                  substyle_index === undefined ||
                  substyle_index === null
                ) {
                  return;
                }
                ShowMintModal(
                  prompt,
                  getPolygonNFTBaseURI("Custom", prompt, wid as string),
                  wid as string,
                  navigate
                );
                return;
              }
              ShowMintModal(
                animal as string,
                getPolygonNFTBaseURI(
                  c_style as string,
                  animal as string,
                  wid as string
                ),
                wid as string,
                navigate
              );
            }}
          >
            Mint NFT
          </Button>
        )}

        {workflowStatus !== WorkflowStatus.Running && (
          <>
            <Button
              type={
                workflowStatus === WorkflowStatus.Success
                  ? "default"
                  : "primary"
              }
              shape="round"
              size="large"
              onClick={() => {
                if (txt2img === "1") {
                  if (
                    !prompt ||
                    prompt === "" ||
                    !cfg ||
                    cfg === "" ||
                    substyle_index === undefined ||
                    substyle_index === null
                  ) {
                    return;
                  }

                  // Get the substyle data
                  const substyle_index_int = parseInt(substyle_index);
                  const substyle = STYLE_CUSTOM_HINT[substyle_index_int];

                  if (!substyle) {
                    return;
                  }

                  const finalPrompt = `${prompt} ${substyle.data.text}`;

                  // convert cfg from string to number
                  const cfgNum = parseFloat(cfg);
                  createTxt2ImgWorkflow(finalPrompt, cfgNum)
                    .then((res) => {
                      // Check if the response is valid
                      if (res?.data?.err === "" && res?.data?.wid) {
                        // Navigate to the next page
                        navigate(
                          `/generate?wid=${
                            res.data.wid
                          }&prompt=${encodeURIComponent(
                            prompt
                          )}&cfg=${encodeURIComponent(
                            cfg
                          )}&txt2img=1&substyle=${encodeURIComponent(
                            substyle_index
                          )}`
                        );
                      } else {
                        Toast.show({
                          content:
                            "Failed to create workflow. Err:" + res?.data?.err,
                        });
                      }
                    })
                    .catch((err) => {
                      console.log("There is an error:", err);
                      Toast.show({
                        content: err.message,
                      });
                    });
                  return;
                }

                createImg2ImgWorkflow(
                  c_style as string,
                  parseInt(c_selected as string)
                )
                  .then((res) => {
                    // Check if the response is valid
                    if (res?.data?.err === "" && res?.data?.wid) {
                      // Navigate to the next page
                      navigate(
                        `/generate?wid=${res.data.wid}&display_style=${display_style}&animal=${animal}&c_selected=${c_selected}&c_style=${c_style}`
                      );
                    } else {
                      Toast.show({
                        content:
                          "Failed to create workflow. Err:" + res?.data?.err,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log("There is an error:", err);
                    Toast.show({
                      content: err.message,
                    });
                  });
              }}
            >
              Regenerate
            </Button>
            {/* {workflowStatus === WorkflowStatus.Success && (
              <div>Own this AI art by minting it as NFT!</div>
            )} */}
          </>
        )}
      </div>
    </OuterContainer>
  );
};
