import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./view/nopage";
import { AvatarSelectPage } from "./view/avatar_select_page/avatar_select_page";
import { SharedPage } from "./view/shared_page/shared_page";
import { Home } from "./view/home/home";
import { AvatarGeneratePage } from "./view/avatar_generate_page/avatar_generate_page";
import { AvatarNftPage } from "./view/avatar_nft_page/avatar_nft_page";
import { GreetingCardPage } from "./view/greeting_card_page/greeting_card";
import "./App.less";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="shared" element={<SharedPage />} />
          <Route path="select" element={<AvatarSelectPage />} />
          <Route path="generate" element={<AvatarGeneratePage />} />
          <Route path="nft" element={<AvatarNftPage />} />
          <Route path="/business/greeting-card/:claimCode" element={<GreetingCardPage />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
