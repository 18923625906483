import { FC, ReactNode, useState } from "react";
import RightArrow from "../../asset/icon/rightArrow.svg";

const NFTAnswer = () => <div>
<p>An NFT (non-fungible token) is a unique digital item stored on a blockchain. NFTs can represent almost anything, and serve as a digital record of ownership. <a href="https://opensea.io/learn/what-are-nfts">Learn more</a></p>
</div>

const NFTCardAnswer = () => <div>
    <p>Compared to the traditional holiday greeting card, NFT provides a new, environmental-friendly and innovative way to send and receive holiday greetings. More importantly, they are collectible and can be stored and shared for years to come. The NFT holiday greeting cards can be considered as a special memento of the holiday season from your partners, teammates, friends and family.</p>
</div>

const MetamaskAnswer = () => <div>
    <p>Go to <a href="https://metamask.io/">MetaMask.io</a> and click the “Download” button and it will take you to the relevant store to download the extension or app based on the device and browser you’re using. For general questions, please refer to <a href="https://metamask.io/faqs/">the FAQ</a> prepared by MetaMask team.</p>
</div>

const AccessNFTAnswer = () => <div>
    <p>It’s much easier to view the NFTs through your PC browser than mobile app. You simply need to click “Portfolio site” link on the MetaMask PC extension and you will be directed to a webpage with all your asset details, no matter which blockchain you are in.</p>
    <p>If you want to have detailed guideline, please read this step by step manual prepared by CrossSpace team at <a href="https://medium.com/@crossspace/100nft-guide-how-to-claim-and-access-your-holiday-greeting-nfts-d8c921ea4f35">[100NFT Guide] How to claim and access your holiday greeting NFTs.</a></p>
</div>

export const QAContent = [{
    question: 'What is an NFT?',
    answer: <NFTAnswer />,
}, {
    question: 'What’s special about NFT Greeting Card?',
    answer: <NFTCardAnswer />,
}, {
    question: 'How to install MetaMask?',
    answer: <MetamaskAnswer />
}, {
    question: 'How to access my holiday greeting NFTs from 100NFT?',
    answer: <AccessNFTAnswer />
}]

export const QAItem: FC<{
    item: {
        question: string,
        answer: ReactNode,
    }
}> = ({
    item
}) => {
    const [showAnswer, setShowAnswer] = useState(false);
    return (
        <div className="flex flex-col gap-[15px] border-bottom">
            <button className={`flex justify-between items-center pl-[20px] pr-[20px] pt-[12px] pb-[12px] ${showAnswer && 'bg-[#ddd]'}`} onClick={() => setShowAnswer(!showAnswer)}>
                <div className="font-normal text-[14px] text-left">{item.question}</div>
                <div className="flex gap-[4px]"><img src={RightArrow} alt="right arrow" className={!showAnswer ? '' : 'rotate-90'} /></div>
            </button>
            <div className={`font-normal text-[14px] leading-normal text-[#535D68] pl-[20px] pr-[20px] pb-[15px] text-left ${!showAnswer ? 'hidden' : 'block'}`}>{item.answer}</div>
        </div>
    )
}