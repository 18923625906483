export const OuterContainer = (props: { children: React.ReactNode }) => {
  return (
    <div className="w-full h-full flex flex-auto flex-col items-center">
      {/* The outer container is always full width and full view port height */}
      {/* The inner container is at max 2xl so if displayed in a larger screen it will not stretched fully */}
      <div className="max-w-2xl h-full w-full grow flex flex-auto flex-col items-center pt-[16px] px-[16px]">
        {props.children}
      </div>
    </div>
  );
};
