import { SingleImageData } from "../component/img_select/img_select";
export const IMG_SELECT_BASE_URL =
  "https://storage.googleapis.com/100nft_website_assets/animal_sample/";

export const getImgUrl = (relativePath: string) =>
  IMG_SELECT_BASE_URL + relativePath;

export const IMG_3D: SingleImageData[] = [
  {
    path: "3d/Bear.jpg",
    name: "Bear",
    style: "3d",
    display_style: "3D",
    index: 0,
  },
  {
    path: "3d/Cat.jpg",
    name: "Cat",
    style: "3d",
    display_style: "3D",
    index: 1,
  },
  {
    path: "3d/Dog.jpg",
    name: "Dog",
    style: "3d",
    display_style: "3D",
    index: 2,
  },
  {
    path: "3d/Duck.jpg",
    name: "Duck",
    style: "3d",
    display_style: "3D",
    index: 3,
  },
  {
    path: "3d/Eagle.jpg",
    name: "Eagle",
    style: "3d",
    display_style: "3D",
    index: 4,
  },
  {
    path: "3d/Fox.jpg",
    name: "Fox",
    style: "3d",
    display_style: "3D",
    index: 5,
  },
  {
    path: "3d/Hamster.jpg",
    name: "Hamster",
    style: "3d",
    display_style: "3D",
    index: 6,
  },
  {
    path: "3d/Hedgehog.jpg",
    name: "Hedgehog",
    style: "3d",
    display_style: "3D",
    index: 7,
  },
  {
    path: "3d/Koala.jpg",
    name: "Koala",
    style: "3d",
    display_style: "3D",
    index: 8,
  },
  {
    path: "3d/Lion.jpg",
    name: "Lion",
    style: "3d",
    display_style: "3D",
    index: 9,
  },
  {
    path: "3d/Monkey.jpg",
    name: "Monkey",
    style: "3d",
    display_style: "3D",
    index: 10,
  },
  {
    path: "3d/Owl.jpg",
    name: "Owl",
    style: "3d",
    display_style: "3D",
    index: 11,
  },
  {
    path: "3d/Panda.jpg",
    name: "Panda",
    style: "3d",
    display_style: "3D",
    index: 12,
  },
  {
    path: "3d/Parrot.jpg",
    name: "Parrot",
    style: "3d",
    display_style: "3D",
    index: 13,
  },
  {
    path: "3d/Penguin.jpg",
    name: "Penguin",
    style: "3d",
    display_style: "3D",
    index: 14,
  },
  {
    path: "3d/Pig.jpg",
    name: "Pig",
    style: "3d",
    display_style: "3D",
    index: 15,
  },
  {
    path: "3d/Rabbit.jpg",
    name: "Rabbit",
    style: "3d",
    display_style: "3D",
    index: 16,
  },
  {
    path: "3d/Raccoon.jpg",
    name: "Raccoon",
    style: "3d",
    display_style: "3D",
    index: 17,
  },
  {
    path: "3d/Reindeer.jpg",
    name: "Reindeer",
    style: "3d",
    display_style: "3D",
    index: 18,
  },
  {
    path: "3d/Wolf.jpg",
    name: "Wolf",
    style: "3d",
    display_style: "3D",
    index: 19,
  },
];

export const IMG_2D: SingleImageData[] = [
  {
    path: "2d/Bear.jpg",
    name: "Bear",
    style: "2d",
    display_style: "2D",
    index: 0,
  },
  {
    path: "2d/Cat.jpg",
    name: "Cat",
    style: "2d",
    display_style: "2D",
    index: 1,
  },
  {
    path: "2d/Dog.jpg",
    name: "Dog",
    style: "2d",
    display_style: "2D",
    index: 2,
  },
  {
    path: "2d/Duck.jpg",
    name: "Duck",
    style: "2d",
    display_style: "2D",
    index: 3,
  },
  {
    path: "2d/Eagle.jpg",
    name: "Eagle",
    style: "2d",
    display_style: "2D",
    index: 4,
  },
  {
    path: "2d/Fox.jpg",
    name: "Fox",
    style: "2d",
    display_style: "2D",
    index: 5,
  },
  {
    path: "2d/Hamster.jpg",
    name: "Hamster",
    style: "2d",
    display_style: "2D",
    index: 6,
  },
  {
    path: "2d/Hedgehog.jpg",
    name: "Hedgehog",
    style: "2d",
    display_style: "2D",
    index: 7,
  },
  {
    path: "2d/Koala.jpg",
    name: "Koala",
    style: "2d",
    display_style: "2D",
    index: 8,
  },
  {
    path: "2d/Lion.jpg",
    name: "Lion",
    style: "2d",
    display_style: "2D",
    index: 9,
  },
  {
    path: "2d/Monkey.jpg",
    name: "Monkey",
    style: "2d",
    display_style: "2D",
    index: 10,
  },
  {
    path: "2d/Owl.jpg",
    name: "Owl",
    style: "2d",
    display_style: "2D",
    index: 11,
  },
  {
    path: "2d/Panda.jpg",
    name: "Panda",
    style: "2d",
    display_style: "2D",
    index: 12,
  },
  {
    path: "2d/Parrot.jpg",
    name: "Parrot",
    style: "2d",
    display_style: "2D",
    index: 13,
  },
  {
    path: "2d/Penguin.jpg",
    name: "Penguin",
    style: "2d",
    display_style: "2D",
    index: 14,
  },
  {
    path: "2d/Pig.jpg",
    name: "Pig",
    style: "2d",
    display_style: "2D",
    index: 15,
  },
  {
    path: "2d/Rabbit.jpg",
    name: "Rabbit",
    style: "2d",
    display_style: "2D",
    index: 16,
  },
  {
    path: "2d/Raccoon.jpg",
    name: "Raccoon",
    style: "2d",
    display_style: "2D",
    index: 17,
  },
  {
    path: "2d/Reindeer.jpg",
    name: "Reindeer",
    style: "2d",
    display_style: "2D",
    index: 18,
  },
  {
    path: "2d/Wolf.jpg",
    name: "Wolf",
    style: "2d",
    display_style: "2D",
    index: 19,
  },
];

export const IMG_Pixel: SingleImageData[] = [
  {
    path: "pixel/Bear.jpg",
    name: "Bear",
    style: "pixel",
    display_style: "Pixel",
    index: 0,
  },
  {
    path: "pixel/Cat.jpg",
    name: "Cat",
    style: "pixel",
    display_style: "Pixel",
    index: 1,
  },
  {
    path: "pixel/Dog.jpg",
    name: "Dog",
    style: "pixel",
    display_style: "Pixel",
    index: 2,
  },
  {
    path: "pixel/Duck.jpg",
    name: "Duck",
    style: "pixel",
    display_style: "Pixel",
    index: 3,
  },
  {
    path: "pixel/Eagle.jpg",
    name: "Eagle",
    style: "pixel",
    display_style: "Pixel",
    index: 4,
  },
  {
    path: "pixel/Fox.jpg",
    name: "Fox",
    style: "pixel",
    display_style: "Pixel",
    index: 5,
  },
  {
    path: "pixel/Hamster.jpg",
    name: "Hamster",
    style: "pixel",
    display_style: "Pixel",
    index: 6,
  },
  {
    path: "pixel/Hedgehog.jpg",
    name: "Hedgehog",
    style: "pixel",
    display_style: "Pixel",
    index: 7,
  },
  {
    path: "pixel/Koala.jpg",
    name: "Koala",
    style: "pixel",
    display_style: "Pixel",
    index: 8,
  },
  {
    path: "pixel/Lion.jpg",
    name: "Lion",
    style: "pixel",
    display_style: "Pixel",
    index: 9,
  },
  {
    path: "pixel/Monkey.jpg",
    name: "Monkey",
    style: "pixel",
    display_style: "Pixel",
    index: 10,
  },
  {
    path: "pixel/Owl.jpg",
    name: "Owl",
    style: "pixel",
    display_style: "Pixel",
    index: 11,
  },
  {
    path: "pixel/Panda.jpg",
    name: "Panda",
    style: "pixel",
    display_style: "Pixel",
    index: 12,
  },
  {
    path: "pixel/Parrot.jpg",
    name: "Parrot",
    style: "pixel",
    display_style: "Pixel",
    index: 13,
  },
  {
    path: "pixel/Penguin.jpg",
    name: "Penguin",
    style: "pixel",
    display_style: "Pixel",
    index: 14,
  },
  {
    path: "pixel/Pig.jpg",
    name: "Pig",
    style: "pixel",
    display_style: "Pixel",
    index: 15,
  },
  {
    path: "pixel/Rabbit.jpg",
    name: "Rabbit",
    style: "pixel",
    display_style: "Pixel",
    index: 16,
  },
  {
    path: "pixel/Raccoon.jpg",
    name: "Raccoon",
    style: "pixel",
    display_style: "Pixel",
    index: 17,
  },
  {
    path: "pixel/Reindeer.jpg",
    name: "Reindeer",
    style: "pixel",
    display_style: "Pixel",
    index: 18,
  },
  {
    path: "pixel/Wolf.jpg",
    name: "Wolf",
    style: "pixel",
    display_style: "Pixel",
    index: 19,
  },
];
