import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { OuterContainer } from "../../component/outer_container";
import { Button, Tooltip, Radio } from "antd";
import { Image, Space, Toast } from "antd-mobile";
import "./override.css";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import logo from "../../asset/icon/logo_h.svg";
import celebrate from "../../asset/icon/celebrate.svg";
import wave from "../../asset/icon/wave.svg";
import { ImgWaiting, ImgWaitingStatus } from "../../component/img_waiting";
import { ShowShareModal, webBaseURL } from "../../component/share_modal";
import {
  getNFTContract,
  getTransactionByHash,
  getTransactionReceipt,
} from "../../api/web3";
import { NFTv1 } from "../../const/nftv1";
import {
  getImageUrlByWorkflowId,
  getOpenSeaUrlByContractAddress,
  getPolygonScanUrlByTxHash,
} from "./nft_url_utils";

enum TxnStatus {
  None,
  Minting,
  Success,
  Failed,
  NotFound,
}

export const AvatarNftPage = () => {
  const navigate = useNavigate();
  const [mintedImgUrl, setMintedImgUrl] = useState("");
  const [txnStatus, setTxnStatus] = useState(TxnStatus.None);
  const [searchParams, setSearchParams] = useSearchParams();
  const contract_address = searchParams.get("contract_address");
  const transaction_hash = searchParams.get("transaction_hash");
  const wid = searchParams.get("wid");
  const name = searchParams.get("name");
  const isShared = transaction_hash === null;
  const checkCurrentTxnStatus = async () => {
    try {
      if (isShared) {
        // There is no txn hash provided, so we assume the nft has already been minted and this page is opened in shared mode
        setMintedImgUrl(getImageUrlByWorkflowId(wid as string));
        setTxnStatus(TxnStatus.Success);
        return TxnStatus.Success;
      }

      // There is a txn. Check if txn is mined
      const txn = await getTransactionByHash(transaction_hash);
      if (!txn) {
        // There is no txn with this hash
        setTxnStatus(TxnStatus.NotFound);
        return TxnStatus.NotFound;
      }
      if (txn.confirmations <= 0) {
        // Txn is not mined. Wait for it to be mined
        setTxnStatus(TxnStatus.Minting);
        return TxnStatus.Minting;
      }

      // Txn is mined. Check if txn is successful
      const txnReceipt = await getTransactionReceipt(transaction_hash);
      if (txnReceipt.status) {
        // Txn is successful
        setMintedImgUrl(getImageUrlByWorkflowId(wid as string));
        setTxnStatus(TxnStatus.Success);
        return TxnStatus.Success;
      } else {
        // Txn is failed
        setTxnStatus(TxnStatus.Failed);
        return TxnStatus.Failed;
      }
    } catch (err) {
      console.log(err);
      setTxnStatus(TxnStatus.Failed);
      return TxnStatus.Failed;
    }
  };

  useEffect(() => {
    // Check if we have any valid wid
    if (!contract_address) {
      Toast.show({
        content: "Invalid contract address",
      });
      return;
    }

    (async () => {
      // Check the status
      const status = await checkCurrentTxnStatus();
      if (status === TxnStatus.Minting) {
        console.log("Running the timer");
        const interval = setInterval(() => {
          (async () => {
            console.log("Checking the status");
            const result = await checkCurrentTxnStatus();
            if (result !== TxnStatus.Minting) {
              console.log("Clear the timer");
              clearInterval(interval);
              return;
            }
          })();
        }, 3000);
      }
    })();
  }, [contract_address]);

  return (
    <OuterContainer>
      <div className="h-[64px] w-full">
        <div className="flex flex-row h-full w-full">
          <div className="h-full w-full flex flex-col items-start">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="h-full flex-none flex flex-col items-center justify-center">
            {!isShared && (
              <Button
                shape="circle"
                color="primary"
                icon={<CloseOutlined />}
                size="large"
                onClick={() => {
                  navigate("/");
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-full w-full flex flex-col pt-[10px]">
        <div className="w-full flex flex-col flex-none">
          <div className="h-full w-full text-left">
            {txnStatus === TxnStatus.Minting && (
              <div className="text-[14px] pb-5">
                Minting your AI art... (
                <a
                  href={getPolygonScanUrlByTxHash(transaction_hash as string)}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Txn
                </a>
                )
              </div>
            )}
            {(txnStatus === TxnStatus.NotFound ||
              txnStatus === TxnStatus.Failed ||
              txnStatus === TxnStatus.None) && (
              <div className="text-[14px] pb-5 text-rose-500">
                Error: Failed to mint your AI art
              </div>
            )}
            {txnStatus === TxnStatus.Success && !isShared && (
              <div className="text-[14px] pb-5">
                <img src={celebrate} className="inline-block" />
                Great, you have owned this AI art!
              </div>
            )}
            {txnStatus === TxnStatus.Success && isShared && (
              <div className="text-[14px] pb-5">
                <img src={wave} className="inline-block" />
                You can create a new AI art based on this style, or browse all
                animals and styles.
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full text-left flex flex-col items-center">
          {txnStatus === TxnStatus.Minting && (
            <ImgWaiting status={ImgWaitingStatus.Running} />
          )}
          {(txnStatus === TxnStatus.NotFound ||
            txnStatus === TxnStatus.Failed ||
            txnStatus === TxnStatus.None) && (
            <ImgWaiting status={ImgWaitingStatus.Failed} />
          )}
          {txnStatus === TxnStatus.Success && (
            <Image
              src={mintedImgUrl}
              width={"100%"}
              fit="cover"
              style={{
                borderRadius: "10px",
                maxWidth: "50vh",
              }}
            />
          )}
        </div>
        <div className="h-full w-full text-left flex flex-col items-left pt-3">
          <div className="pt-2 text-[18px] font-bold">{name || "Name"}</div>
          <div className="text-[16px] text-[#999FA5]">
            {contract_address || "Contract Address"}
          </div>
        </div>
      </div>
      <div className="fixed bottom-8 flex flex-col-reverse max-w-2xl w-full px-[60px] roundBtn gap-4">
        {txnStatus === TxnStatus.Minting && (
          <Button
            type="primary"
            shape="round"
            size="large"
            icon={<SyncOutlined spin />}
            disabled={txnStatus === TxnStatus.Minting}
          >
            Minting your NFT...
          </Button>
        )}

        {txnStatus === TxnStatus.Success && !isShared && (
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              ShowShareModal(`${webBaseURL}/nft?wid=${wid}&name=${name}&contract_address=${contract_address}`);
            }}
          >
            Share your art
          </Button>
        )}

        {txnStatus === TxnStatus.Success && isShared && (
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              navigate("/");
            }}
          >
            Create my AI art
          </Button>
        )}

        <Button
          type={"default"}
          shape="round"
          size="large"
          disabled={txnStatus !== TxnStatus.Success}
          onClick={() => {
            window.open(
              getOpenSeaUrlByContractAddress(contract_address as string),
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          View in OpenSea
        </Button>
      </div>
    </OuterContainer>
  );
};
