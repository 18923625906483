import axios from "axios";
import { BASE_API_URL } from "./api_const";

export const checkWorkflow = async (workflowId: string) => {
  return await axios({
    method: "get",
    url: BASE_API_URL + "/wf/" + workflowId,
    timeout: 4000, // 4 seconds timeout
  });
};
