import { Image, Space, Toast } from "antd-mobile";
import { Spin } from "antd";
import { SmileOutlined } from "@ant-design/icons";

export enum ImgWaitingStatus {
  Running,
  Failed,
}
export interface ImgWaitingProps {
  status: ImgWaitingStatus;
}

export const ImgWaiting = (props: ImgWaitingProps) => {
  if (props.status === ImgWaitingStatus.Failed) {
    return (
      <div className="h-screen w-full text-center flex flex-col items-center justify-center  max-w-[50vh] max-h-[50vh] rounded-[10px] bg-[#E7E8E8]">
        <SmileOutlined rotate={180} style={{ fontSize: "25px" }} />
      </div>
    );
  }
  return (
    <div className="h-screen w-full text-center flex flex-col items-center justify-center  max-w-[50vh] max-h-[50vh] rounded-[10px] bg-[#E7E8E8]">
      <Spin size="large" />
    </div>
  );
};
