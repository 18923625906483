import { Modal, Toast } from "antd-mobile";
import { Button } from "antd";
import twitter from "../asset/icon/twitter.svg";
import copy from "../asset/icon/copy.svg";
import { TwitterShareButton } from "next-share";

export const webBaseURL = "https://app.100nft.app";

// For general use case, will pass shareUrl and shareText as parameters to the below component
// export const generateShareUrl = (
//   wid: string,
//   name: string,
//   contract_address: string
// ) => {
//   return `${webBaseURL}?wid=${wid}&name=${name}&contract_address=${contract_address}`;
// };

export const ShowShareModal = (url: string, text?: string) => {
  const handle = Modal.show({
    content: (
      <div className="flex flex-col items-center gap-4 max-w-[400px]">
        <div className="text-[14px] text-[#333333]">
          Choose an option to share:
        </div>
        <TwitterShareButton
          url={url}
          title={text || "Check out my AI generated NFT at app.100NFT.app"}
          blankTarget={true}
          hashtags={["NFT", "100NFT", "AIGC", "CrossSpace"]}
          via={"CSpaceOfficial"}
        >
          <Button type="primary" shape="round" size="large">
            <img src={twitter} alt="twitter" className="inline-block mx-2" />
            Share to Twitter
          </Button>
        </TwitterShareButton>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={() => {
            navigator.clipboard.writeText(url);
            Toast.show({
              content: "Share link copied to clipboard",
            });
          }}
        >
          <img src={copy} alt="copy" className="inline-block mx-2" />
          Copy Share Link
        </Button>
        <Button
          type="default"
          shape="round"
          size="large"
          onClick={() => {
            handle.close();
          }}
        >
          Done
        </Button>
      </div>
    ),
  });
};