import { SingleRadioBtnProps } from "../component/radio_btn_group/radio_btn_group";

export const STYLE_SELECT_RADIO_BTN_GROUP: SingleRadioBtnProps[] = [
  {
    title: "Custom",
    data: "custom",
  },
  {
    title: "3D",
    data: "3d",
  },
  {
    title: "2D",
    data: "2d",
  },
  {
    title: "Pixel",
    data: "pixel",
  },
];
