import { EmptyBox } from "../../component/empty_box";
import { OuterContainer } from "../../component/outer_container";
import bg from "../../asset/bg/bg1.png";
import logo from "../../asset/icon/logo.svg";
import csplogo from "../../asset/icon/csp.svg";
import { Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <OuterContainer>
      <div
        className={`h-screen w-full bg-repeat flex flex-auto flex-col items-center`}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="w-full">
          <img src={logo} className="m-auto mt-4" />
          <div className="m-4 mt-4 text-left">
            <h3>Create your free-to-own AI art</h3>
            <p>
              Are you tired of limited options for your NFT avatars? 100NFT is
              the right tool that helps you generate INFINITE options of
              avatars! Only four simple steps:
            </p>
            <br />
            <ol>
              <li>Describe the image you want to generate at Prompt Words</li>
              <li>
                Choose CFG Scale (how much the image looks closer to the prompt)
              </li>
              <li>Generate avatar with one click</li>
              <li>
                Own your unique NFT avatar by minting it via your Metamask
                wallet on the Polygon network
              </li>
            </ol>
          </div>
        </div>
        <div className="fixed bottom-8 flex flex-col max-w-2xl w-full px-[60px]">
          <Button
            block
            shape="rounded"
            color="primary"
            onClick={() => {
              navigate("/select");
            }}
          >
            Let’s go!
          </Button>
          <div className="text-[12px] mt-[16px] ">
            <a href="https://crossspace.io" target="_blank">
              <img src={csplogo} className="inline-block mx-2 " />
            </a>
            Powered by{" "}
            <a href="https://crossspace.io" target="_blank">
              CrossSpace
            </a>
          </div>
        </div>
      </div>
    </OuterContainer>
  );
};
