import axios from "axios";
import { BASE_API_URL } from "./api_const";

export const createImg2ImgWorkflow = async (
  style: string,
  img_index: number
) => {
  return await axios({
    method: "post",
    url: BASE_API_URL + "/createImg2ImgNFT",
    timeout: 4000, // 4 seconds timeout
    data: {
      style,
      img_index,
    },
  });
};

export const createTxt2ImgWorkflow = async (prompt: string, cfg: number) => {
  return await axios({
    method: "post",
    url: BASE_API_URL + "/createTxt2ImgNFT",
    timeout: 4000, // 4 seconds timeout
    data: {
      prompt,
      cfg,
    },
  });
};
