import { Toast } from "antd-mobile";
import { isMobile } from "react-device-detect";
import { connectWallet, continueInMetamask, ensurePolygonNetwork, isWeb3AndMetamaskInstalled, validateChainId } from "../api/web3";

export const checkWalletInstalled = async() => {
    if (!isWeb3AndMetamaskInstalled()) {
        // First need to check if we are on mobile
        if (isMobile) {
            // Continue using metamask deeplink
            continueInMetamask();
            return;
        }
        Toast.show({
            content: "Please install Metamask",
        });
        throw new Error('Please install Metamask');
    }
}

export const checkWallet = async () => {
    const wallet = await connectWallet();
    if (!wallet) {
      Toast.show({
        content: "Failed to connect to wallet",
      });

      throw new Error('Failed to connect to wallet')
    }

    // Check network
    await ensurePolygonNetwork();

    // Check
    if (!(await validateChainId())) {
      Toast.show({
        content: "Please switch to Polygon network",
      });
      throw new Error('Please switch to Polygon network')
    }

    return wallet;
}