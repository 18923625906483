import { createSlice } from "@reduxjs/toolkit";
import { SingleImageData } from "../component/img_select/img_select";

export interface EntireAppState {
  // Empty
}

const initialState: EntireAppState = {};

const entireAppStateSlice = createSlice({
  name: "entireAppState",
  initialState,
  reducers: {
    // Empty
  },
});

export const { reducer } = entireAppStateSlice;
