import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {Button, Image} from "antd";
import { Toast, DotLoading } from "antd-mobile";
import Logo from "../../asset/icon/logo_h.svg";
import Claimed from "../../asset/icon/claimed.svg";
import LinkSvg from "../../asset/icon/link.svg";
import LogoSlate from "../../asset/icon/logo_slate.svg";
import TwitterSlate from "../../asset/icon/twitter_slate.svg";
import DiscordSlate from "../../asset/icon/discord_slate.svg";
import FallbackPicture from "../../asset/bg/bg1.png";
import { QAContent, QAItem } from "./qa";
import { ShowShareModal } from "../../component/share_modal";
import { HostedMintModalWithAddress } from "../../component/hosted_mint_modal_with_address/hosted_mint_modal_with_address";
import { getInfoByClaimCode, GetInfoByClaimCodeResponse, ClaimCodeStatus, claimAndMint } from "../../api/greeting_card";
import { formatTime, getNowTimeStamp, getTimeStamp } from "../../utils/time";
import { GreetingCardInfo } from "./card_info";
import { linkAccordingToChainAndType, OpenseaFn } from "../../utils/chain";
import "./override.css";

export const GreetingCardPage = () => {
  const { claimCode } = useParams();
  const [info, setInfo] = useState<GetInfoByClaimCodeResponse>({
    id: '',
    code: '',
    chain_id: 0,
    contract_address: '',
    token_id: '',
    claim_wallet_address: '',
    status: ClaimCodeStatus.Deployed,
    expires_at: '',
    image_url: '',
    sender_name: '',
    sender_link: '',
    sender_logo_file_id: '',
    card_content: '',
    share_content: '',
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [step, setStep] = useState< 1 | 2 | 3>(1);
  const [error, setError] = useState(false);

  const isExpired = useMemo(() => getNowTimeStamp() - getTimeStamp(formatTime(info.expires_at)) > 0, [info.expires_at]);
  const openseaLink = (linkAccordingToChainAndType(info.chain_id)('opensea') as OpenseaFn)(info.contract_address, info.token_id);

  const getCardInfo = useCallback(() => getInfoByClaimCode(claimCode || '').then((result: GetInfoByClaimCodeResponse) => {
    setInfo(result);
  }, (e) => {
    setError(true)
    Toast.show({
      content:
        e.message || <p>Please make sure the <br/> code is correct</p>,
        duration: 3000
    });
  }), [claimCode])

  useEffect(() => {
    getCardInfo()
  }, [claimCode, getCardInfo]);

  return error ? <div className="w-screen h-screen flex items-center justify-center"><DotLoading /></div>  : (
    <div className="overflow-x-hidden overflow-y-auto font-[Sora] bg-[#EAEAEA] text-[#333333] h-screen w-screen">
      <div className="bg-white pl-[20px] pr-[20px] pb-[20px]">
        <div className="md:max-w-[640px] mx-auto">
          <div className="h-[84px]">
              <div className="flex h-full items-center justify-between">
                <div className="h-full w-auto flex items-center">
                  <Image
                    preview={false}
                    height="32px"
                    src={info.sender_logo_file_id}
                    fallback={Logo}
                    alt="logo"
                    className="object-contain logo-img"

                  />
                </div>
                <div className="flex gap-[5px] items-center">
                  <div className="font-bold text-[16px]">Greeting Card</div>
                  <div className="font-semibold text-[14px] text-white bg-[#7750F8] p-[5px] rounded-t-lg rounded-br-lg">NFT</div>
                </div>
              </div>
            </div>
            <div className="md:max-w-[500px] mx-auto">
              <div className="font-normal text-[14px] text-left">{info.card_content}</div>
              <div className="shadow-[10px_10px_8px_0_rgba(0,0,0,0.1)] w-full mt-[22px]">
                <Image
                  width="100%"
                  height="auto"
                  src={info.image_url}
                  preview={false}
                  placeholder={
                    <Image
                      preview={false}
                      src={`${info.image_url}?x-oss-process=image/blur,r_50,s_50/quality`}
                    />}
                  fallback={FallbackPicture}
                  style={{display:'inline'}}
                />
              </div>

              {/* normal claim */}
              {
                info.status === ClaimCodeStatus.Deployed && !isExpired && <div className="max-w-[350px] mx-auto">
                  <Button type="primary" shape="round" size="large" className="w-full mt-[20px]" onClick={() => setModalVisible(true)}>Claim to wallet</Button>
                  <div className="font-normal text-[10px] text-[#999FA5]">expire in <b>90 days</b></div>
                </div>
              }

              {/* already claimed */}
              {
                info.status === ClaimCodeStatus.Claimed && <div className="max-w-[350px] mx-auto">
                  <button className="rounded-full bg-[#E7E8E8] w-full h-[65px] mt-[20px] flex justify-between items-center box-border pl-[20px] pr-[20px]">
                    <div className="flex items-center gap-[10px]">
                      <img src={Claimed} alt="claimed nft"/>
                      <div className="flex flex-col items-start">
                        <span className="text-[12px] font-[400] leading-relaxed">Owned By</span>
                        <span className="text-[12px] font-[700] leading-relaxed text-ellipsis overflow-hidden block whitespace-nowrap max-w-[120px]">{info.claim_wallet_address}</span>
                      </div>
                    </div>
                    <a className="flex gap-[5px]" href={openseaLink}>
                      <div className="text-[#7750F8] text-[12px] font-[400]">View in Opensea</div>
                      <img src={LinkSvg} alt="link to opensea" />
                    </a>
                  </button>
                  <Button type="primary" shape="round" size="large" className="w-full mt-[20px]" onClick={() => {ShowShareModal(`https://n.csp.one/${info.code}`, info.share_content || (`I just received this amazing NFT greeting card from @cyberport_hk! I am so excited to add this unique and collectible AI artwork to my collection!`))}}>Share</Button>
                </div>
              }

              {/* expired */}
              {
                isExpired && info.status === ClaimCodeStatus.Deployed && <div className="max-w-[350px] mx-auto">
                  <button disabled className="rounded-full bg-[#fafafa] text-[#999FA5] font-semibold text-[14px] w-full h-[52px] mt-[20px] border-solid border-[#E7E8E8] border-2">Claim to wallet</button>
                  <div className="font-normal text-[10px] text-[#999FA5]">This NFT Card hasn't been claimed in the past <b>90 days</b></div>
                </div>
              }
            </div>
        </div>
      </div>
      <div className="md:max-w-[640px] mx-auto flex flex-col items-start ">
        <div className="pl-[20px] mb-[5px] mt-[30px] font-bold text-[16px]">NFT Card Info</div>
        <GreetingCardInfo info={info}/>
        <div className="pl-[20px] mb-[5px] mt-[30px] font-bold text-[16px]">Know More</div>
        <div className="w-full">
          {
            QAContent.map((item, index) => {
              return <QAItem key={index} item={item}/>
            })
          }
        </div>
      </div>
      <footer className="flex flex-col items-center mt-[50px] mb-[54px] text-[#999FA5]">
          <p className="flex gap-[10px] items-center mb-[10px]">Powered by <img src={LogoSlate} alt="100nft logo" /></p>
          <p className="mb-[10px]">The AIGC NFT Launchpad by <a href="https://www.crossspace.io/" className="underline underline-offset-2 text-[#999FA5]">CrossSpace</a>.</p>
          <p className="mb-[15px]">For cooperation, please contact us:</p>
          <div className="flex justify-around items-center gap-[22px]">
            <a href="https://twitter.com/CSpaceOfficial"><img src={TwitterSlate} alt="CrossSpace twitter" /></a>
            <a href="https://discord.com/invite/crossspace"><img src={DiscordSlate} alt="CrossSpace discord" /></a>
          </div>
      </footer>
      
      <HostedMintModalWithAddress 
        text={<span className="text-[#535D68] ">The NFT Greeting Card will be airdropped to the <b className="font-bold">Wallet Address</b> using Polygon network.</span>} 
        successText="Congratulations! You have claimed this NFT." 
        visible={modalVisible} 
        setVisible={setModalVisible}
        setStep={setStep}
        step={step}
        id={info.id as string}
        mintFunction={(id: string, walletAddress: string, cb: () => void) => claimAndMint(id, walletAddress).then(() => {
          setStep(3);
          getCardInfo();
        }, (e) => {
          Toast.show({
            content: e,
            duration: 3000
          });
          cb()
        })}
      />
    </div>
  );
};


